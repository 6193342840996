<template>
  <div class="page" id="create-call-bridge">
    <div class="bridge">
      <div class="card p-3" v-if="loading">
        <b-spinner label="Loading..." class="mx-auto"></b-spinner>
      </div>
      <div class="p-3 card" v-else>
        <div class="mb-4 basic">
          <h4 class="mt-0 mb-2">
            {{ $t('call-bridge.create.form.titles.basic') }}
          </h4>
          <div class="row">
            <div class="col-12">
              <Field
                :label="$t('call-bridge.create.form.name.label')"
                :placeholder="$t('call-bridge.create.form.name.placeholder')"
                :info="$t('call-bridge.create.form.name.info')"
                v-model="model.name"
                :error="errors[`name`]"
                disabled
              >
              </Field>
            </div>
            <div class="col-12 col-md-6">
              <Field
                :label="
                  $t('call-bridge.create.form.firstRecipientTrialsCount.label')
                "
                :error="errors[`firstRecipientTrialsCount`]"
                :info="
                  $t('call-bridge.create.form.firstRecipientTrialsCount.info')
                "
              >
                <b-form-spinbutton
                  class="border"
                  v-model="model.firstRecipientTrialsCount"
                  min="1"
                  max="10"
                  wrap
                  placeholder="-"
                  disabled
                />
              </Field>
            </div>
            <div class="col-12 col-md-6">
              <Field
                :label="
                  $t('call-bridge.create.form.secondRecipientTrialsCount.label')
                "
                :error="errors[`secondRecipientTrialsCount`]"
                :info="
                  $t('call-bridge.create.form.secondRecipientTrialsCount.info')
                "
              >
                <b-form-spinbutton
                  class="border"
                  v-model="model.secondRecipientTrialsCount"
                  min="1"
                  max="10"
                  wrap
                  placeholder="-"
                  disabled
                />
              </Field>
            </div>

            <div class="col-12 col-md-6">
              <Field
                :label="
                  $t(
                    'call-bridge.create.form.firstRecipientDelayMinutesBetweenTrials.label'
                  )
                "
                :error="errors[`firstRecipientDelayMinutesBetweenTrials`]"
                :info="
                  $t(
                    'call-bridge.create.form.firstRecipientDelayMinutesBetweenTrials.info'
                  )
                "
              >
                <b-form-spinbutton
                  class="border"
                  v-model="model.firstRecipientDelayMinutesBetweenTrials"
                  min="0"
                  max="5"
                  wrap
                  placeholder="-"
                  disabled
                />
              </Field>
            </div>
            <div class="col-12 col-md-6">
              <Field
                :label="
                  $t(
                    'call-bridge.create.form.secondRecipientDelayMinutesBetweenTrials.label'
                  )
                "
                :error="errors[`secondRecipientDelayMinutesBetweenTrials`]"
                :info="
                  $t(
                    'call-bridge.create.form.secondRecipientDelayMinutesBetweenTrials.info'
                  )
                "
              >
                <b-form-spinbutton
                  class="border"
                  v-model="model.secondRecipientDelayMinutesBetweenTrials"
                  min="0"
                  max="5"
                  wrap
                  placeholder="-"
                  disabled
                />
              </Field>
            </div>
            <div class="col-12 col-md-6">
              <Field
                :label="
                  $t('call-bridge.create.form.warningTimeBeforeEnd.label')
                "
                :error="errors[`warningTimeBeforeEnd`]"
                :info="$t('call-bridge.create.form.warningTimeBeforeEnd.info')"
              >
                <b-form-input
                  class="border"
                  v-model="model.warningTimeBeforeEnd"
                  type="number"
                  min="1"
                  @keydown="onlyAcceptNumbers"
                  disabled
                />
              </Field>
            </div>
          </div>
        </div>

        <div class="mb-4 sounds">
          <h4 class="mt-0 mb-2">
            {{ $t('call-bridge.create.form.titles.sounds') }}
          </h4>
          <div class="row">
            <div class="col-12 col-lg-6">
              <Field
                :label="$t('call-bridge.create.form.welcomeSound.label')"
                :info="$t('call-bridge.create.form.welcomeSound.info')"
                :error="errors[`welcomeSound`]"
              >
                <dropzone-file
                  v-if="model.welcomeSoundFileName && !model.welcomeSound"
                  :file="{
                    name: model.welcomeSoundFileName
                  }"
                  readonly
                />
                <Dropzone
                  v-else
                  :options="{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }"
                  v-model="model.welcomeSound"
                  disabled
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{ $t('call-bridge.create.form.welcomeSound.title') }}
                    </h3>
                    <div class="subtitle">
                      {{ $t('call-bridge.create.form.welcomeSound.subtitle') }}
                    </div>
                    <div class="description">
                      {{
                        $t('call-bridge.create.form.welcomeSound.description', {
                          exts: '.mp3'
                        })
                      }}
                    </div>
                  </div>
                </Dropzone>
              </Field>
            </div>
            <div class="col-12 col-lg-6">
              <Field
                :label="$t('call-bridge.create.form.alertSound.label')"
                :info="$t('call-bridge.create.form.alertSound.info')"
                :error="errors[`alertSound`]"
              >
                <dropzone-file
                  v-if="model.alertSoundFileName && !model.alertSound"
                  :file="{
                    name: model.alertSoundFileName
                  }"
                  readonly
                />
                <Dropzone
                  v-else
                  :options="{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }"
                  v-model="model.alertSound"
                  disabled
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{ $t('call-bridge.create.form.alertSound.title') }}
                    </h3>
                    <div class="subtitle">
                      {{ $t('call-bridge.create.form.alertSound.subtitle') }}
                    </div>
                    <div class="description">
                      {{
                        $t('call-bridge.create.form.alertSound.description', {
                          exts: '.mp3'
                        })
                      }}
                    </div>
                  </div>
                </Dropzone>
              </Field>
            </div>
            <div class="col-12 col-lg-6">
              <Field
                :label="
                  $t('call-bridge.create.form.firstRecipientSorrySound.label')
                "
                :info="
                  $t('call-bridge.create.form.firstRecipientSorrySound.info')
                "
                :error="errors[`firstRecipientSorrySound`]"
              >
                <dropzone-file
                  v-if="
                    model.firstRecipientSorrySoundFileName &&
                      !model.firstRecipientSorrySound
                  "
                  :file="{
                    name: model.firstRecipientSorrySoundFileName
                  }"
                  readonly
                />
                <Dropzone
                  v-else
                  :options="{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }"
                  v-model="model.firstRecipientSorrySound"
                  disabled
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{
                        $t(
                          'call-bridge.create.form.firstRecipientSorrySound.title'
                        )
                      }}
                    </h3>
                    <div class="subtitle">
                      {{
                        $t(
                          'call-bridge.create.form.firstRecipientSorrySound.subtitle'
                        )
                      }}
                    </div>
                    <div class="description">
                      {{
                        $t(
                          'call-bridge.create.form.firstRecipientSorrySound.description',
                          {
                            exts: '.mp3'
                          }
                        )
                      }}
                    </div>
                  </div>
                </Dropzone>
              </Field>
            </div>
            <div class="col-12 col-lg-6">
              <Field
                :label="
                  $t('call-bridge.create.form.secondRecipientSorrySound.label')
                "
                :info="
                  $t('call-bridge.create.form.secondRecipientSorrySound.info')
                "
                :error="errors[`secondRecipientSorrySound`]"
              >
                <dropzone-file
                  v-if="
                    model.secondRecipientSorrySoundFileName &&
                      !model.secondRecipientSorrySound
                  "
                  :file="{
                    name: model.secondRecipientSorrySoundFileName
                  }"
                  readonly
                />
                <Dropzone
                  v-else
                  :options="{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }"
                  v-model="model.secondRecipientSorrySound"
                  disabled
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{
                        $t(
                          'call-bridge.create.form.secondRecipientSorrySound.title'
                        )
                      }}
                    </h3>
                    <div class="subtitle">
                      {{
                        $t(
                          'call-bridge.create.form.secondRecipientSorrySound.subtitle'
                        )
                      }}
                    </div>
                    <div class="description">
                      {{
                        $t(
                          'call-bridge.create.form.secondRecipientSorrySound.description',
                          {
                            exts: '.mp3'
                          }
                        )
                      }}
                    </div>
                  </div>
                </Dropzone>
              </Field>
            </div>
            <div class="col-12 col-lg-6">
              <Field
                :label="$t('call-bridge.create.form.warningSound.label')"
                :info="$t('call-bridge.create.form.warningSound.info')"
                :error="errors[`warningSound`]"
              >
                <dropzone-file
                  v-if="model.warningSoundFileName && !model.warningSound"
                  :file="{
                    name: model.warningSoundFileName
                  }"
                  readonly
                />
                <Dropzone
                  v-else
                  :options="{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }"
                  v-model="model.warningSound"
                  disabled
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{ $t('call-bridge.create.form.warningSound.title') }}
                    </h3>
                    <div class="subtitle">
                      {{ $t('call-bridge.create.form.warningSound.subtitle') }}
                    </div>
                    <div class="description">
                      {{
                        $t('call-bridge.create.form.warningSound.description', {
                          exts: '.mp3'
                        })
                      }}
                    </div>
                  </div>
                </Dropzone>
              </Field>
            </div>
          </div>
        </div>

        <div class="mb-4 callers">
          <h4 class="mt-0 mb-2">
            {{ $t('call-bridge.create.form.titles.callerIds') }}
          </h4>
          <div class="callers-container row">
            <div
              v-for="(caller, idx) in model.callers"
              :key="caller.id"
              class="align-items-start col-12 row"
            >
              <div class="col-11 row">
                <div class="col-12 col-md-6">
                  <Field
                    :label="
                      $t('call-bridge.create.form.callerIds.destination.label')
                    "
                    :info="
                      $t('call-bridge.create.form.callerIds.destination.info')
                    "
                    :error="errors[`callers[${idx}].destination`]"
                  >
                    <b-form-select
                      class="border"
                      @change="onCountryChange($event, idx, caller.id)"
                      v-model="model.callers[idx].destination"
                      disabled
                    >
                      <b-form-select-option
                        v-for="country in countries"
                        :key="country.code"
                        :value="country.code"
                        :disabled="
                          model.callers[idx].destination !== country.code &&
                            selectedCountries.includes(country.code)
                        "
                      >
                        {{ country.emoji }} {{ country.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </Field>
                </div>

                <div class="col-12 col-md-6">
                  <Field
                    :label="
                      $t('call-bridge.create.form.callerIds.callerId.label')
                    "
                    :info="
                      $t('call-bridge.create.form.callerIds.callerId.info')
                    "
                    :error="errors[`callers[${idx}].callerNumber`]"
                  >
                    <b-form-select
                      class="border"
                      v-model="model.callers[idx].callerNumber"
                      :options="callerNumbers"
                      disabled
                    />
                  </Field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Field from '../../../elements/Field.vue'
import Dropzone from '../../../elements/Dropzone.vue'
import vocabService from '../../../services/vocab.service.js'
import CallBridgesService from '../../../services/callBridge.service.js'
import DropzoneFile from '../../../elements/DropzoneFile.vue'
import { onlyAcceptNumbers } from '../../../utils/utils.js'
import { mapGetters } from 'vuex'

const defaultCaller = {
  id: new Date().getTime(),
  destination: '',
  callerNumber: ''
}

export default {
  name: 'CreateCallBridge',
  components: {
    Field,
    Dropzone,
    DropzoneFile
  },
  data() {
    return {
      model: {
        name: '',
        firstRecipientTrialsCount: 1,
        secondRecipientTrialsCount: 1,
        firstRecipientDelayMinutesBetweenTrials: 0,
        secondRecipientDelayMinutesBetweenTrials: 0,
        welcomeSound: null,
        alertSound: null,
        firstRecipientSorrySound: null,
        secondRecipientSorrySound: null,
        warningSound: null,
        warningTimeBeforeEnd: 1,
        callers: [{ ...defaultCaller }]
      },
      errors: {},
      countries: [],
      selectedCountries: [],
      callerNumbers: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['currentTenant']),
    bridgeId() {
      return this.$route.params.id
    }
  },
  methods: {
    onlyAcceptNumbers,
    // handlers
    handleAddCaller() {
      this.model.callers.push({
        ...defaultCaller,
        id: new Date().getTime()
      })
    },
    handleRemoveCaller(index) {
      const caller = this.model.callers[index]
      this.selectedCountries = this.selectedCountries.filter(
        country => country !== caller.destination
      )

      this.model.callers.splice(index, 1)
      this.errors[`callers[${index}].destination`] = null
      this.errors[`callers[${index}].callerNumber`] = null
    },
    onCountryChange(value, idx) {
      const temp = [...this.selectedCountries]
      temp[idx] = value
      this.selectedCountries = temp
    },
    // api calls
    async getCurrentBridge() {
      if (!this.bridgeId) return
      try {
        this.loading = true
        const res = await CallBridgesService.getOneBridge(this.bridgeId)
        const flow = res.data.flow
        this.model = {
          ...flow,
          callers: flow.callers.map(caller => ({
            ...caller,
            id: new Date().getTime()
          }))
        }
      } catch (err) {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getCountries() {
      const countries = await vocabService.getCountries()
      this.countries = countries
    },
    async getCallerNumbers() {
      const callerNumbers = await vocabService.getCallerIds()
      this.callerNumbers = callerNumbers.map(number => ({
        text: number,
        value: number
      }))
    }
  },
  mounted() {
    this.getCountries()
    this.getCallerNumbers()
    this.getCurrentBridge()
  },
  watch: {
    async currentTenant() {
      this.getCountries()
      this.getCallerNumbers()
      this.getCurrentBridge()
    }
  }
}
</script>

<style scoped lang="scss"></style>
